<script setup lang="ts">
    import type { CarouselCardData } from '~/@types/cms';

    export interface CarouselSlideProps {
        slide: CarouselCardData;
        textColor: string;
        backgroundColor: string;
        isActive: boolean;
        isDuplicate?: boolean;
    }
    const props = defineProps<CarouselSlideProps>();

    const isDarkBackground = computed(() => {
        return props.backgroundColor === 'black';
    });
</script>
<template>
    <div :class="['org-carousel-slide-content m-auto flex flex-col', { 'bg-[#FF0000]': isDuplicate }]">
        <div class="relative flex shrink items-center justify-center">
            <lazy-atm-link
                v-if="slide.link"
                class="aspect-3/2 overflow-hidden"
                :class="{ 'pointer-events-none': !isActive }"
                :link="slide.link"
                link-only>
                <lazy-atm-image
                    v-if="slide.image"
                    :sizes="nuxtImageSizes.carousel"
                    :src="slide.image"
                    :alt="removeWoomTextFormat(slide.title || '')"
                    provider="assets"
                    cover />
            </lazy-atm-link>
        </div>

        <div :class="['flex shrink flex-col gap-2 text-center', { 'pt-2': isActive, 'opacity-0': !isActive }]">
            <div
                v-if="slide.title"
                :class="{ textColor }">
                <renderer-html :content="woomTextFormat(slide.title)" />
            </div>

            <div
                v-if="slide.subtitle"
                class="m-auto max-w-sm">
                <renderer-html :content="woomTextFormat(slide.subtitle)" />
            </div>
            <div class="m-auto flex w-full max-w-sm items-center justify-center gap-2 py-2 max-md:flex-col">
                <lazy-atm-link
                    v-if="slide.link"
                    :link="slide.link"
                    :styling="isDarkBackground ? 'solid-invert' : 'solid-secondary'" />

                <lazy-atm-link
                    v-if="slide.link2"
                    :link="slide.link2"
                    :styling="isDarkBackground ? 'outline-invert' : 'outline'" />
            </div>
        </div>
    </div>
</template>
