<script setup lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import type { Asset, BackgroundColorsPredefined, CarouselCardData } from '~/@types/cms';
    import type { ProductPreview } from '~/graphql/generated';

    export interface CarouselProps {
        contentData?: CarouselCardData[];
        productData?: CMSProduct[];
        products?: Partial<ProductPreview>[];
        title?: string;
        description?: string;
        link?: string;
        linkText?: string | null;
        textColor?: string;
        backgroundColor?: BackgroundColorsPredefined;
        backgroundImage?: Asset;
        colorFilter?: string;
        componentOrigin?: string;
    }
    const nuxtApp = useNuxtApp();

    const props = withDefaults(defineProps<CarouselProps>(), { backgroundColor: 'default', textColor: '' });

    const { colorMapper } = useStyle();
    const { randomHash } = useUtils();
    const { setMainSwiper, checkSwipe, swipeLeft, swipeRight, isBeginning, isEnd, ssrIsActive } = useGallery();

    const uniqueId = randomHash('alphaLower');

    const productData = filterProductColor(props.products, props.colorFilter);
    const sendToGtm = () => {
        if (checkSwipe()) {
            nuxtApp.$gtm.pushEvent({
                event: props.products ? 'click_product_carousel' : 'click_content_carousel',
            });
        }
    };

    const loopedSlides = computed(() => {
        if (!productData) return 0;

        return productData.length >= 3 * 2 ? 3 : Math.floor(productData.length / 2);
    });

    const swiperItems = <T,>(items: T[] | undefined) => {
        // make sure we have enough items for loop
        if (!items) {
            return [];
        }
        if (items.length > 3) {
            return items;
        }
        return [...items, ...items];
    };
</script>

<template>
    <section
        class="org-carousel relative"
        :class="[colorMapper[backgroundColor], { 'text-white': backgroundColor === 'black' }]">
        <atm-image
            class="absolute h-full w-full"
            :data="backgroundImage"
            sizes="100vw md:1264px lg:1920px"
            cover />

        <div class="relative py-8 md:py-10">
            <atm-grid v-if="title || description">
                <div
                    :class="textColor"
                    class="col-span-2 text-center md:col-span-12 lg:col-span-8 lg:col-start-3">
                    <div
                        v-if="title"
                        class="pb-6 text-3xl font-bold md:text-4xl">
                        <renderer-html :content="woomTextFormat(title)" />
                    </div>
                    <div
                        v-if="description"
                        class="pb-6">
                        <renderer-html :content="woomTextFormat(description)" />
                    </div>
                </div>
            </atm-grid>

            <!-- should fit in viewport height -->
            <div
                v-if="productData?.length || contentData?.length"
                :class="['gtm-click-carousel flex flex-col', contentData?.length ? 'content-slide' : 'min-h-full lg:h-screen-desktop']">
                <nuxt-error-boundary @error="() => {}">
                    <swiper
                        class="relative !flex min-h-[30rem] w-full flex-grow items-center"
                        :scrollbar="{ draggable: true, el: '.swiper-scrollbar' }"
                        :instance-name="uniqueId"
                        :centered-slides="true"
                        loop
                        slides-per-view="auto"
                        :looped-slides="loopedSlides"
                        @slide-change="sendToGtm"
                        @swiper="setMainSwiper">
                        <swiper-slide
                            v-for="(slide, index) in swiperItems(contentData)"
                            :key="slide.id"
                            v-slot="{ isActive, isDuplicate }"
                            class="!h-100% !flex !w-[75%] !scale-90 overflow-hidden opacity-50 transition-all duration-300 ease-in-out md:!w-[70%] md:!scale-75 lg:!w-[50%] [&.swiper-slide-active]:!scale-100 [&.swiper-slide-active]:opacity-100">
                            <org-carousel-slide-content
                                :slide="slide"
                                :text-color="textColor"
                                :background-color="backgroundColor"
                                :is-active="ssrIsActive(isActive, index)"
                                :is-duplicate="isDuplicate"
                                :inert="!ssrIsActive(isActive, index)" />
                        </swiper-slide>
                        <swiper-slide
                            v-for="(slide, index) in swiperItems(productData)"
                            :key="slide.id"
                            v-slot="{ isActive, isDuplicate }"
                            class="!h-100% !w-[75%] !scale-90 overflow-hidden opacity-50 transition-all duration-300 ease-in-out md:!w-[70%] md:!scale-75 lg:!w-[50%] [&.swiper-slide-active]:!scale-100 [&.swiper-slide-active]:opacity-100">
                            <org-carousel-slide-product
                                :slide="slide"
                                :text-color="textColor"
                                :background-color="backgroundColor"
                                :is-active="ssrIsActive(isActive, index)"
                                :is-duplicate="isDuplicate"
                                :inert="!ssrIsActive(isActive, index)" />
                        </swiper-slide>

                        <mol-swiper-navigation
                            :swipe-left="swipeLeft"
                            :swipe-right="swipeRight"
                            :is-beginning="isBeginning"
                            :is-end="isEnd" />
                    </swiper>
                </nuxt-error-boundary>

                <lazy-atm-grid v-if="linkText && link">
                    <div class="col-span-2 m-auto flex w-full justify-center md:col-span-12">
                        <nuxt-link
                            :to="$helpers.generateLocalePath(link)"
                            class="underline">
                            {{ linkText }}
                        </nuxt-link>
                    </div>
                </lazy-atm-grid>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .org-carousel {
        --dynamic-desktop-height: 7rem;
    }
    @media (min-height: 800px) {
        .content-slide {
            @apply min-h-full lg:h-screen-desktop;
        }
    }
</style>
